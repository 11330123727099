import React, { useMemo, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import {
	selectRadiosPending,
	selectToken,
	selectRadiosServerErrorMessage,
	selectCategories,
	selectRadiosMessage,
} from '../../selectors';
import {
	RadiosPendingColumnsInfos,
	globalTableStyle,
	globalThStyle,
	globalTdStyle,
	modalStyles,
} from '../../utils/const';
import { getRadiosPending, validateRadioPending, cancelRadioPending } from '../../services/Radios';
import { setMessage, setErrorServerMessage, setRadiosPending } from '../../features/radiosSlice';
import { getCategories } from '../../services/Categories';
import InsideMenu from '../../components/InsideMenu';
import GlobalFilter from '../../components/GlobalFilter';
import ElementsToShow from '../../components/ElementsToShow';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import GoToTop from '../../components/GoToTop';
import { scrollTop, compareIgnoreCase, numericSort } from '../../utils/functions';

function RadiosPending() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const radioGroup = location.pathname.split('/').pop();
	const [isLoaded, setLoaded] = useState(false);
	const [data, setData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [audioStopped, setIsAudioStopped] = useState(true);
	const [streamForm, setStreamForm] = useState({ type: '', url_part_2: '', duration: -1, secret: '' });
	const [streamInUpdate, setStreamInUpdate] = useState({ rpID: null, index: null, streamId: null });
	const radiosPending = useSelector(selectRadiosPending);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const categories = useSelector(selectCategories);

	useEffect(() => {
		scrollTop();
	}, []);
	useEffect(() => {
		dispatch(setRadiosPending(''));
	}, [dispatch]);

	useEffect(() => {
		if (token) {
			dispatch(getRadiosPending(token, radioGroup === 'nrj' ? 'O' : null));
			dispatch(getCategories(token, 'radios'));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, radioGroup]);
	useEffect(() => {
		if (radiosPending) {
			setData(radiosPending.pendings);
			setLoaded(true);
		}
	}, [dispatch, radiosPending]);

	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/radios');
	}

	const columns = useMemo(() => RadiosPendingColumnsInfos, []);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			sortTypes: {
				alphanumeric: (row1, row2, columnName) => {
					if (columnName === 'rpid - Name') {
						return numericSort(row1.values[columnName], row2.values[columnName]);
					} else return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
				},
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const refuseModifications = (row) => {
		setIsModalOpen(true);
		dispatch(cancelRadioPending(token, row.original.rpID));
		setIsAudioStopped(true);
	};
	const acceptModifications = (row) => {
		setIsModalOpen(true);
		dispatch(validateRadioPending(token, row.original.rpID));
		setIsAudioStopped(true);
	};
	const actions = [
		{ funct: acceptModifications, name: 'Accepter' },
		{ funct: refuseModifications, name: 'Refuser' },
	];

	const eraseErrorMessage = () => {
		dispatch(setMessage(''));
		dispatch(setErrorServerMessage(''));
	};

	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to="/radios" className="fs-5 " onClick={() => dispatch(setErrorServerMessage(''))}>
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div>
			<InsideMenu
				homeLink={'/radios/pending'}
				title={`Validation Des Modifications En Attente ${radioGroup === 'nrj' ? ' - NRJ Group' : ''}`}
				links={[]}
			/>
			<div className="overflow-x-scroll scrollNone  d-flex justify-content-center flex-column bg-white rounded-4 p-5 mx-4">
				<div className="sorteWrapper">
					<ElementsToShow pageSize={pageSize} setPageSize={setPageSize} />
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
						dataName={'radios'}
					/>
				</div>
				<Table
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
					page={page}
					tableStyle={globalTableStyle}
					thStyle={globalThStyle}
					tdStyle={globalTdStyle}
					actions={actions}
					isLoaded={isLoaded}
					categories={categories}
					pendings={data}
					audioStopped={audioStopped}
					streamForm={streamForm}
					setStreamForm={setStreamForm}
					setStreamInUpdate={setStreamInUpdate}
					streamInUpdate={streamInUpdate}
				/>
				<Pagination
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageIndex={pageIndex}
					pageOptions={pageOptions}
				/>
			</div>
			<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
				{successMessage ? (
					<div className="text-success">
						Opération réussie
						<br />
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary w-30"
								onClick={() => {
									setIsModalOpen(false);
									eraseErrorMessage();
									dispatch(getRadiosPending(token, radioGroup === 'nrj' ? 'O' : null));
									setData([]);
								}}
							>
								Retour
							</button>
						</div>
					</div>
				) : null}
			</Modal>
			<GoToTop />
		</div>
	);
}

export default RadiosPending;
