import React from 'react';
import './style.css';
import PendingRadiosInnerTable from '../PendingRadiosInnerTable';
import RadioLogosInnerTable from '../RadioLogosInnerTable';
import Spinner from '../../components/Spinner';

function Table({
	getTableProps,
	getTableBodyProps,
	headerGroups,
	prepareRow,
	page,
	tableStyle,
	thStyle,
	tdStyle,
	actions,
	isLoaded,
	radiosLogos,
	pendings,
	categories,
	audioStopped,
	streamForm,
	setStreamForm,
	setStreamInUpdate,
	streamInUpdate,
}) {
	if (!isLoaded) {
		return <Spinner />;
	} else if (page.length === 0) {
		return <div>Aucune donnée disponible dans le tableau</div>;
	}
	return (
		<table {...getTableProps()} style={tableStyle}>
			<thead>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<th {...column.getHeaderProps(column.getSortByToggleProps())} style={thStyle}>
								{column.render('Header')}
								<span>{column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}</span>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{page.map((row, i) => {
					prepareRow(row);
					return (
						<tr {...row.getRowProps()}>
							{row.cells.map((cell) => {
								return (
									<td {...cell.getCellProps()} style={tdStyle} className={`${cell.column.className}`}>
										{cell.column.id === 'ActionsID' ? (
											actions.map((action, i) =>
												action ? (
													<div
														type="button"
														className={
															action.name === 'Supprimer'
																? 'actionButton text-danger my-1'
																: 'actionButton my-1'
														}
														key={i}
														onClick={() => action.funct(row)}
													>
														{action.name}
													</div>
												) : null
											)
										) : cell.column.id === 'ModifID' ? (
											<PendingRadiosInnerTable
												pendings={pendings}
												row={row}
												categories={categories}
												audioStopped={audioStopped}
												streamForm={streamForm}
												setStreamForm={setStreamForm}
												setStreamInUpdate={setStreamInUpdate}
												streamInUpdate={streamInUpdate}
											/>
										) : cell.column.id === 'LogoID' ? (
											<RadioLogosInnerTable row={row} radiosLogos={radiosLogos} />
										) : (
											cell.render('Cell')
										)}
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

export default Table;
