import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logosInfos, radiosInfos, streamsInfos } from '../../utils/const';
import { dateFormattingWithMnSec } from '../../utils/functions';
import Loader from '../../assets/loading-new.gif';
import AudioPlayer from '../AudioPlayer';
import { updatePendingStream, getRadiosPending } from '../../services/Radios';
import { selectToken } from '../../selectors';
import { TokenType } from '../../utils/enum';

function PendingRadiosInnerTable({
	pendings,
	categories,
	row,
	audioStopped,
	streamForm,
	setStreamForm,
	setStreamInUpdate,
	streamInUpdate,
}) {
	const dispatch = useDispatch();
	const location = useLocation();

	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const radioGroup = location.pathname.split('/').pop();
	const findRadio = (radios, row) => {
		return radios.find((radio) => radio.rpID === row.original.rpID);
	};
	const findCategory = (catId) => {
		return categories.find((cat) => cat.id === catId).nom;
	};
	const updateStream = (name, value) => {
		setStreamForm((prevInputs) => ({
			...prevInputs,
			[name]: value,
			...(name === 'type' && (value !== TokenType.M6 || value !== TokenType.IndesRadios)
				? { duration: -1, url_part_2: '', secret: '' }
				: {}),
		}));
	};

	const resetUpdatedStreamInfos = () => {
		setStreamForm({ type: '', url_part_2: '', duration: -1, secret: '' });
		setStreamInUpdate({ rpID: null, index: null, streamId: null });
	};
	useEffect(() => {
		resetUpdatedStreamInfos();
	}, []);

	const submitForm = (event) => {
		event.preventDefault();
		dispatch(updatePendingStream(token, streamInUpdate.rpID, streamInUpdate.streamId, streamForm));
		dispatch(getRadiosPending(token, radioGroup === 'nrj' ? 'O' : null));
		resetUpdatedStreamInfos();
	};
	return (
		<div>
			{radiosInfos.map((info, i) =>
				findRadio(pendings, row)[info.name] ? (
					<div key={`radio${i}`} className="d-flex align-items-center my-3 justify-content-start">
						<div className="w-50 me-5 text-end">{info.title} :</div>
						<div className="w-100 d-flex justify-content-start text-start">
							{info.name.includes('cat_')
								? findCategory(findRadio(pendings, row)[info.name])
								: info.name === 'updatedAt'
									? dateFormattingWithMnSec(findRadio(pendings, row)[info.name])
									: findRadio(pendings, row)[info.name]}
						</div>
					</div>
				) : null
			)}
			{findRadio(pendings, row).streams.map((radioStream, i) => (
				<div key={`index${i}`} className="border rounded py-2 my-2">
					<div className="d-flex justify-content-center ">
						<div className="w-50 me-5 text-end fw-bold">Url :</div>
						<div key={`url${i}`} className="text-start w-100 d-flex justify-content-start flex-column">
							<a key={`stream${i}`} href={radioStream.url} target="blank">
								{radioStream.url}
							</a>
						</div>
					</div>
					<div className="d-flex justify-content-center my-3">
						<div className="w-50 me-5 text-end fw-bold">Type :</div>
						<div className="text-start w-100 d-flex justify-content-start">
							<form onSubmit={submitForm}>
								<div className="mb-3 col-12">
									<select
										className="form-select"
										name="type"
										disabled={
											streamInUpdate.rpID === radioStream.rpID &&
											streamInUpdate.index === i &&
											streamInUpdate.streamId === radioStream.id
												? false
												: true
										}
										value={
											streamInUpdate.rpID === radioStream.rpID &&
											streamInUpdate.index === i &&
											streamInUpdate.streamId === radioStream.id
												? streamForm.type
												: radioStream.type
										}
										onChange={(e) => updateStream(e.target.name, e.target.value)}
									>
										<option value="">Type</option>
										<option value="icecast">MP3 ou AAC</option>
										<option value="hls">hls</option>
										<option value="rtltoken">rtltoken</option>
										<option value="infomaniaktoken">infomaniaktoken</option>
									</select>
								</div>
								{radioStream.type === TokenType.M6 ||
								radioStream.type === TokenType.IndesRadios ||
								(streamInUpdate.rpID === radioStream.rpID &&
									streamInUpdate.index === i &&
									streamInUpdate.streamId === radioStream.id &&
									(streamForm.type === TokenType.M6 || streamForm.type === TokenType.IndesRadios)) ? (
									<div>
										<div className="mb-3">
											<label className="form-label d-flex justify-content-start">
												Fin d'url:
											</label>
											<input
												className="form-control"
												type="text"
												name="url_part_2"
												key={`part2_${i}`}
												id={`part2_${i}`}
												disabled={
													streamInUpdate.rpID === radioStream.rpID &&
													streamInUpdate.index === i &&
													streamInUpdate.streamId === radioStream.id
														? false
														: true
												}
												value={
													streamInUpdate.rpID === radioStream.rpID &&
													streamInUpdate.index === i &&
													streamInUpdate.streamId === radioStream.id
														? streamForm.url_part_2
														: radioStream.url_part_2
												}
												onChange={(e) => updateStream(e.target.name, e.target.value)}
											/>
										</div>
										<div className="mb-3">
											<label className="form-label d-flex justify-content-start">
												Clé token:
											</label>
											<input
												className="form-control"
												type="text"
												name="secret"
												key={`secret_${i}`}
												id={`secret_${i}`}
												disabled={
													streamInUpdate.rpID === radioStream.rpID &&
													streamInUpdate.index === i &&
													streamInUpdate.streamId === radioStream.id
														? false
														: true
												}
												value={
													streamInUpdate.rpID === radioStream.rpID &&
													streamInUpdate.index === i &&
													streamInUpdate.streamId === radioStream.id
														? streamForm.secret
														: radioStream.secret
												}
												onChange={(e) => updateStream(e.target.name, e.target.value)}
											/>
										</div>
										<div className="mb-3">
											<label className="form-label d-flex justify-content-start">
												Durée (mn):
											</label>
											<input
												className="form-control"
												type="number"
												name="duration"
												key={`duration_${i}`}
												id={`duration_${i}`}
												disabled={
													streamInUpdate.rpID === radioStream.rpID &&
													streamInUpdate.index === i &&
													streamInUpdate.streamId === radioStream.id
														? false
														: true
												}
												value={
													streamInUpdate.rpID === radioStream.rpID &&
													streamInUpdate.index === i &&
													streamInUpdate.streamId === radioStream.id
														? streamForm.duration
														: radioStream.duration
												}
												onChange={(e) => updateStream(e.target.name, e.target.value)}
											/>
										</div>
									</div>
								) : null}
								<div className="d-flex mt-2">
									<div
										className={
											streamInUpdate.rpID === radioStream.rpID &&
											streamInUpdate.index === i &&
											streamInUpdate.streamId === radioStream.id
												? 'border px-2 py-1 rounded bg-white'
												: streamInUpdate.rpID === null &&
													  streamInUpdate.index === null &&
													  streamInUpdate.streamId === null
													? 'border px-2 py-1 rounded bg-white'
													: null
										}
										style={{ cursor: 'pointer' }}
										onClick={(e) => {
											setStreamInUpdate({
												rpID:
													streamInUpdate.rpID === radioStream.rpID &&
													streamInUpdate.index === i &&
													streamInUpdate.streamId === radioStream.id
														? null
														: radioStream.rpID,
												index:
													streamInUpdate.rpID === radioStream.rpID &&
													streamInUpdate.index === i &&
													streamInUpdate.streamId === radioStream.id
														? null
														: i,
												streamId:
													streamInUpdate.rpID === radioStream.rpID &&
													streamInUpdate.index === i &&
													streamInUpdate.streamId === radioStream.id
														? null
														: radioStream.id,
											});
											setStreamForm({
												type: radioStream.type ? radioStream.type : '',
												url_part_2: radioStream.url_part_2 ? radioStream.url_part_2 : '',
												duration: radioStream.duration ? radioStream.duration : -1,
												secret: radioStream.secret ? radioStream.secret : '',
											});
											if (e.target.innerText === 'Annuler') resetUpdatedStreamInfos();
										}}
									>
										{streamInUpdate.rpID === radioStream.rpID &&
										streamInUpdate.index === i &&
										streamInUpdate.streamId === radioStream.id
											? 'Annuler'
											: streamInUpdate.rpID === null &&
												  streamInUpdate.index === null &&
												  streamInUpdate.streamId === null
												? 'Modifier'
												: ''}
									</div>

									{streamInUpdate.rpID === radioStream.rpID &&
									streamInUpdate.index === i &&
									streamInUpdate.streamId === radioStream.id ? (
										<input
											className="border px-2 py-1 rounded mx-1"
											type="submit"
											value="Enregistrer"
										/>
									) : null}
								</div>
							</form>
						</div>
					</div>
					<div className="d-flex justify-content-center my-3">
						<div className="w-50 me-5 text-end fw-bold">Bitrate :</div>
						<div className="text-start w-100 d-flex justify-content-start">
							{streamsInfos.map((stream, i) =>
								radioStream[stream.name] ? (
									stream.name === 'bitrate' ? (
										<div key={`bitrate${i}`} className="">
											<div>{radioStream[stream.name]} Kbits</div>
										</div>
									) : null
								) : null
							)}
						</div>
					</div>

					<div className="d-flex justify-content-center">
						{streamsInfos.map((stream, i) =>
							radioStream[stream.name] ? (
								stream.name === 'url' ? (
									<div key={`url${i}`} className="text-start w-100 d-flex justify-content-center">
										<AudioPlayer
											audioStopped={audioStopped}
											url={radioStream[stream.name]}
											radioStream={radioStream}
										/>
									</div>
								) : null
							) : null
						)}
					</div>
				</div>
			))}

			{logosInfos.map((dim, i) =>
				findRadio(pendings, row)?.[dim.name] ? (
					<div key={`logo${i}`} className="d-flex align-items-center my-3 justify-content-start">
						<div className="w-50 me-5 text-end">Logo {dim.size}:</div>
						<div className="w-100 d-flex justify-content-start">
							{findRadio(pendings, row)[dim.name] ? (
								<img
									src={findRadio(pendings, row)[dim.name]}
									alt={dim.size}
									width={dim.width}
									height={dim.height}
								/>
							) : (
								<img src={Loader} border="" width={dim.width} height={dim.height} alt="loader" />
							)}
						</div>
					</div>
				) : null
			)}
		</div>
	);
}

export default PendingRadiosInnerTable;
