import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Login from './pages/Login';
import Radios from './pages/Radios';
import RadioUpdate from './pages/RadioManager/RadioUpdate';
import RadioCreate from './pages/RadioManager/RadioCreate';
import RadioLogoOnlyCreate from './pages/RadioManager/RadioLogoOnlyCreate';
import RadioLogoOnlyUpdate from './pages/RadioManager/RadioLogoOnlyUpdate';
import User from './pages/User';
import Users from './pages/Users';
import Header from './components/Header';
import SideBar from './components/SideBar';
import { selectToken } from './selectors';
import { ProtectedAdminRoute, ProtectedRoute, ProtectedGroupRoute } from './protectedRoute';
import { isMobile } from 'react-device-detect';
import RadioPrograms from './pages/RadioPrograms';
import RadiosPending from './pages/RadiosPending';
import UpdateRadioProgram from './pages/RadioProgramManager/UpdateRadioProgram';
import CreateRadioProgram from './pages/RadioProgramManager/CreateRadioProgram';
import Podcasts from './pages/Podcasts';
import PodcastUpdate from './pages/PodcastsManager/PodcastUpdate';
import PodcastCreate from './pages/PodcastsManager/PodcastCreate';
import PodcastsDelete from './pages/PodcastsManager/PodcastsDelete';
import SeriesId from './pages/PodcastsManager/SeriesId';
import PodcastsOnFly from './pages/PodcastsManager/PodcastsOnFly';
import PodcastEpisodes from './pages/PodcastEpisodes';
import EpisodesDelete from './pages/PodcastsEpisodesManager/EpisodesDelete';
import Frequencies from './pages/Frequencies';
import PodcastsOnboardings from './pages/PodcastsManager/PodcastsOnboardings';
import PodcastOnboardingUpdate from './pages/PodcastsManager/PodcastOnboardingUpdate';
import PodcastOnboardingCreate from './pages/PodcastsManager/PodcastOnboardingCreate';
import ArticleCreate from './pages/ArticleCreate';
import Articles from './pages/Articles';
import Footer from './components/Footer';
import Locals from './pages/Locals';
import LocalUpdate from './pages/LocalsManager/LocalUpdate';
import LocalCreate from './pages/LocalsManager/LocalCreate';
import RadiosStatistics from './pages/RadiosStatistics';
import PodcastsStatistics from './pages/PodcastsStatistics';
import PasswordReset from './pages/PasswordReset';
import MediasCounter from './pages/MediasCounter';
import PocIA from './pages/PocIA';
import Logs from './pages/Logs';
import { scrollTop } from './utils/functions';

function App() {
	const location = useLocation();
	const [isSideBarOpen, setSideBarOpen] = useState(true);
	const [isSideBarReduced, setSideBarReduced] = useState(false);
	const [isConnected, setIsConnected] = useState(
		location.pathname !== '/' && location.pathname !== '/reset' && location.pathname !== '/poc_ia'
	);
	const [statsMode, setStatsMod] = useState('');
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const isAdmin = sessionStorage.getItem('isAdmin') === '1';
	const groupId = sessionStorage.getItem('groupId');
	const isAuthorizedGroup = isAdmin || (groupId && groupId <= 6);

	useEffect(() => {
		scrollTop();
	}, []);
	useEffect(() => {
		setIsConnected(location.pathname !== '/' && location.pathname !== '/reset' && location.pathname !== '/poc_ia');
	}, [location]);

	useEffect(() => {
		if (isMobile) {
			setSideBarOpen(false);
			setSideBarReduced(true);
		}
	}, []);

	return (
		<div
			className="App"
			style={{
				paddingLeft:
					token && isSideBarOpen ? '280px' : token && !isSideBarOpen ? '85px' : !token ? '0px' : '0vh',
				paddingBottom: token ? '20vh' : '0',
			}}
		>
			{isConnected ? <Header setIsConnected={setIsConnected} /> : null}
			{isConnected ? (
				<SideBar
					isAdmin={isAdmin}
					isSideBarOpen={isSideBarOpen}
					setSideBarOpen={setSideBarOpen}
					isSideBarReduced={isSideBarReduced}
					setSideBarReduced={setSideBarReduced}
					setStatsMod={setStatsMod}
					groupId={groupId}
				/>
			) : null}
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/reset" element={<PasswordReset />} />
				<Route path="/poc_ia" element={<PocIA />} />
				<Route
					path="/radios"
					element={
						<ProtectedRoute token={token}>
							<Radios isAdmin={isAdmin} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/edit/:rpID"
					element={
						<ProtectedRoute token={token}>
							<RadioUpdate isAdmin={isAdmin} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/pending/others"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<RadiosPending />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/radios/pending/nrj"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<RadiosPending />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/radios/add"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<RadioCreate />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/users"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<Users />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/users/edit/:email"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<User />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/radios/:rpID/programs"
					element={
						<ProtectedRoute token={token}>
							<RadioPrograms isAdmin={isAdmin} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/:rpID/programs/edit/:id"
					element={
						<ProtectedRoute token={token}>
							<UpdateRadioProgram />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/:rpID/programs/add"
					element={
						<ProtectedRoute token={token}>
							<CreateRadioProgram />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/:rpID/podcasts"
					element={
						<ProtectedRoute token={token}>
							<Podcasts groupId={groupId} isAdmin={isAdmin} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/:rpID/podcasts/edit/:id"
					element={
						<ProtectedRoute token={token}>
							<PodcastUpdate groupId={groupId} isAdmin={isAdmin} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/:rpID/podcasts/add"
					element={
						<ProtectedRoute token={token}>
							<PodcastCreate groupId={groupId} isAdmin={isAdmin} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/:rpID/podcasts/delete"
					element={
						<ProtectedRoute token={token}>
							<PodcastsDelete groupId={groupId} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/:rpID/series"
					element={
						<ProtectedRoute token={token}>
							<SeriesId groupId={groupId} isAdmin={isAdmin} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/:rpID/podcasts/on-the-fly/add"
					element={
						<ProtectedRoute token={token}>
							<PodcastsOnFly groupId={groupId} isAdmin={isAdmin} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/:rpID/podcasts/:id/episodes"
					element={
						<ProtectedRoute token={token}>
							<PodcastEpisodes />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/:rpID/podcasts/:id/delete"
					element={
						<ProtectedRoute token={token}>
							<EpisodesDelete />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/:rpID/frequencies/edit"
					element={
						<ProtectedRoute token={token} isAdmin={isAdmin}>
							<Frequencies />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/onboardings"
					element={
						<ProtectedGroupRoute token={token} isAuthorizedGroup={isAuthorizedGroup}>
							<PodcastsOnboardings />
						</ProtectedGroupRoute>
					}
				/>
				<Route
					path="/onboardings/:idSeries/edit"
					element={
						<ProtectedGroupRoute token={token} isAuthorizedGroup={isAuthorizedGroup}>
							<PodcastOnboardingUpdate groupId={groupId} isAdmin={isAdmin} />
						</ProtectedGroupRoute>
					}
				/>
				<Route
					path="/onboardings/add"
					element={
						<ProtectedRoute token={token}>
							<PodcastOnboardingCreate groupId={groupId} isAdmin={isAdmin} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/articles/online"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<Articles />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/articles/validate"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<Articles />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/articles/add"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<ArticleCreate />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/articles/edit/:artID"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<ArticleCreate />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/locals/"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<Locals />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/radios/:rpID/locals"
					element={
						<ProtectedRoute token={token}>
							<Locals isAdmin={isAdmin} />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/radios/:rpID/locals/:id/edit"
					element={
						<ProtectedRoute token={token}>
							<LocalUpdate />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios/:rpID/locals/add"
					element={
						<ProtectedRoute token={token}>
							<LocalCreate />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/radios_logo_only"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<Radios type={3} />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/radios_logo_only/add"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<RadioLogoOnlyCreate type={3} />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/radios_logo_only/:rpID/edit"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<RadioLogoOnlyUpdate type={3} />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/radios_logo_only/:rpID/frequencies/edit"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<Frequencies type={3} />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/locals/add"
					element={
						<ProtectedAdminRoute isAdmin={isAdmin} token={token}>
							<LocalCreate />
						</ProtectedAdminRoute>
					}
				/>
				{/* <Route
					path="/analytics/radioplayer"
					element={
						<ProtectedAdminRoute token={token} isAdmin={isAdmin}>
							<RadiosStatistics statsMode={statsMode} setStatsMod={setStatsMod} />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/analytics/radioplayer/:old"
					element={
						<ProtectedAdminRoute token={token} isAdmin={isAdmin}>
							<RadiosStatistics statsMode={statsMode} setStatsMod={setStatsMod} />
						</ProtectedAdminRoute>
					}
				/> */}
				{/* <Route
					path="/analytics/podcasts_regions"
					element={
						<ProtectedRoute isAdmin={isAdmin} token={token}>
							<PodcastsStatistics />
						</ProtectedRoute>
					}
				/> */}
				<Route
					path="/medias-counter"
					element={
						<ProtectedAdminRoute token={token} isAdmin={isAdmin}>
							<MediasCounter />
						</ProtectedAdminRoute>
					}
				/>
				<Route path="*" element={<Login />} />
				<Route
					path="/logs"
					element={
						<ProtectedAdminRoute token={token} isAdmin={isAdmin}>
							<Logs />
						</ProtectedAdminRoute>
					}
				/>
				<Route path="*" element={<Login />} />
			</Routes>
			{token ? <Footer token={token} isSideBarOpen={isSideBarOpen} /> : null}
		</div>
	);
}

export default App;
