import axios from 'axios';
import {
	setRadios,
	setRadiosLogoOnly,
	setRadioPrograms,
	setRadio,
	setSeriesId,
	setLogos,
	setErrorMessage,
	setMessage,
	setErrorMessage400,
	setErrorServerMessage,
	setRadiosPending,
	addToCorrectProgramsRequestCounter,
	addToBadProgramsRequestCounter,
} from '../../features/radiosSlice';

const { REACT_APP_API_URL } = process.env;

//Get radios  informations with secure token
export const getRadios = (token, type) => {
	const params = {};
	if (type === 3) {
		params.type = 3;
	}
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/radios`, {
				headers: { 'x-auth-token': token },
				params: params,
			})
			.then((response) => {
				type === 3 ? dispatch(setRadiosLogoOnly(response.data)) : dispatch(setRadios(response.data));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

//Get one radio  information
export const getRadio = (token, rpID, type) => {
	const params = {};
	if (type === 3) {
		params.type = 3;
	}

	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/radios/${rpID}`, {
				headers: { 'x-auth-token': token },
				params: params,
			})
			.then((response) => {
				dispatch(setRadio(response.data.radio));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

//Update one radio
export const updateRadio = (token, rpID, data) => {
	return (dispatch) =>
		axios
			.put(`${REACT_APP_API_URL}/radios/${rpID}`, data, {
				headers: {
					'x-auth-token': token,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					dispatch(setErrorMessage400(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};

//Create one radio
export const createRadio = (token, data) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/radios`, data, {
				headers: {
					'x-auth-token': token,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					dispatch(setErrorMessage400(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};

//Get radio programs
export const getRadioPrograms = (token, rpID, isProgramActif) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/radios/${rpID}/programs`, {
				headers: { 'x-auth-token': token },
				params: { actif: isProgramActif },
			})
			.then((response) => {
				dispatch(setRadioPrograms(response.data.programs));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

//Desactivate a radio program
export const desactivateRadioProgram = (token, rpID, id) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/radios/${rpID}/programs/${id}/desactivate`, '', {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

//Activate a radio program
export const activateRadioProgram = (token, rpID, id) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/radios/${rpID}/programs/${id}/activate`, '', {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

//Delete a radio
export const deleteRadio = (token, rpID) => {
	return (dispatch) =>
		axios
			.delete(`${REACT_APP_API_URL}/radios/${rpID}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					'x-auth-token': token,
				},
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

//Delete a radio program
export const deleteRadioProgram = (token, rpID, id) => {
	return (dispatch) =>
		axios
			.delete(`${REACT_APP_API_URL}/radios/${rpID}/programs/${id}`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

//Delete all radio programs from one rpID
export const deleteRadioPrograms = (token, rpID) => {
	return (dispatch) =>
		axios
			.delete(`${REACT_APP_API_URL}/radios/${rpID}/programs`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorMessage('A problem has occurred'));
			});
};

//Update a radio program
export const updateRadioProgram = (token, rpID, id, data) => {
	return (dispatch) =>
		axios
			.put(`${REACT_APP_API_URL}/radios/${rpID}/programs/${id}`, data, {
				headers: {
					'x-auth-token': token,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					dispatch(setErrorMessage400(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};

//Create a radio program
export const createRadioProgram = (token, rpID, data) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/radios/${rpID}/programs`, data, {
				headers: {
					'x-auth-token': token,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				dispatch(setMessage(response.status));
				dispatch(addToCorrectProgramsRequestCounter());
			})
			.catch(function (error) {
				dispatch(addToBadProgramsRequestCounter());
				if (error.response.status === 400) {
					dispatch(setErrorMessage400(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};

export const getRadiosPending = (token, isNrj) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/pendings`, {
				headers: { 'x-auth-token': token },
				params: { nrj: isNrj },
			})
			.then((response) => {
				dispatch(setRadiosPending(response.data));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const validateRadioPending = (token, rpid) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/pendings/${rpid}/validate`, '', {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const cancelRadioPending = (token, rpid) => {
	return (dispatch) =>
		axios
			.post(`${REACT_APP_API_URL}/pendings/${rpid}/cancel`, '', {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const getSeriesIds = (token, rpID) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/radios/${rpID}/podcasts`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(
					setSeriesId(
						response.data.podcasts.map((podcast) => {
							return podcast.series_id;
						})
					)
				);
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

export const getLogos = (token) => {
	return (dispatch) =>
		axios
			.get(`${REACT_APP_API_URL}/logos`, {
				headers: { 'x-auth-token': token },
			})
			.then((response) => {
				dispatch(dispatch(setLogos(response.data.logos)));
			})
			.catch(function (error) {
				dispatch(setErrorServerMessage('A problem has occurred'));
			});
};

//Update pending stream
export const updatePendingStream = (token, rpID, id, data) => {
	return (dispatch) =>
		axios
			.patch(`${REACT_APP_API_URL}/pendings/${rpID}/streams/${id}`, data, {
				headers: {
					'x-auth-token': token,
				},
			})
			.then((response) => {
				dispatch(setMessage(response.status));
			})
			.catch(function (error) {
				if (error.response.status === 400) {
					dispatch(setErrorMessage400(error.response.data.errors));
				} else {
					dispatch(setErrorMessage('A problem has occurred'));
				}
			});
};
